import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import Sidebar from '../components/sidebar'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#1f232b' }}>
          <Helmet title={siteTitle} bodyAttributes={{
            class: 'weefly-main'
          }} />
          <div className="wd-section">
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
        {posts.map(({ node }) => <ArticlePreview key={node.id} article={node} />)}
          
          {/* <div className="pagination_wrap">
            <nav>
              <ul className="pagination">
                <li className="page-item disabled"><a className="page-link" href="#" tabindex="-1">1</a></li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item"><a className="page-link" href="#">4</a></li>
                <li className="page-item"><a className="page-link" href="#"><i className="fa fa-angle-double-right"></i></a></li>
              </ul>
            </nav>
          </div> */}
        </div>
        <Sidebar />
      </div>
    </div>
  </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC } filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          id
          title
          slug
          isoDate: publishDate
          publishDate(formatString: "MMMM Do, YYYY")
          strain
          tags
          author {
            contentful_id
            name
            profileImage: image {
              fixed(width: 80, height: 80) {
                ...GatsbyContentfulFixed
              }
            }
          }
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
