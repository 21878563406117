import React from 'react'
import { Link } from 'gatsby'
import PostHeader from './post-header';

export default ({ article }) => (
  <article className="post-listing vertical-item content-padding big-padding with_background rounded overflow_hidden post format-standard">
    <PostHeader heroImage={article.heroImage} profileImage={article.author.profileImage} isoDate={article.isoDate} publishDate={article.publishDate} slug={article.slug} tags={article.tags} strain={article.strain} />
    <div className="item-content">
      <header className="entry-header">
        <h3 className="entry-title">
          <Link to={article.slug}>{article.title}</Link>
        </h3>
      </header>
      <div className="entry-content">
        <p dangerouslySetInnerHTML={{
          __html: article.description.childMarkdownRemark.html,
        }} />
      </div>
    </div>
  </article>
)
